<main>
    <div>
        <img src="images/goat.png" alt="Goat Gaming Logo"/>
    </div>
    <header>
        <h1>Goat Gaming</h1>
        <h2>🐐&nbsp; A FiveM gaming network</h2>
    </header>
</main>

<style>
    main {
        margin: 1em auto 0 auto;
        padding: 1em 2em;
        border: 1px solid var(--primary);
        display: flex;
        justify-content: space-around;
        max-width: 750px;
    }

    img {
        max-height: 92px;
        width: auto;
    }


    main > div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1em;
    }

    h1 {
        color: orangered;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        text-transform: uppercase;
        font-size: 4em;
        font-weight: 900;
    }

    h2 {
        font-weight: 700;
        margin-top: -3px;
        margin-bottom: .375em;
    }

    header {
        text-align: center;
    }

    @media (max-width: 450px) {
        main {
            flex-direction: column;
            width: 70vw;
        }

        main > div {
            margin: -9px 0 1em 0;
        }

        img {
            justify-content: flex-start;
            max-width: 192px;
            height: auto;
            max-height: inherit;
        }

        h1 {
            display: none;
        }

        h2 {
            font-size: 1.125rem;
        }
    }
</style>