<script>
    import {currentGame} from "../stores"
    
    function onClose() {
        currentGame.set(undefined)
    }
</script>

{#if $currentGame}
    <aside on:click={onClose}>
        <section>
            <nav id="intro">
                <h1>Launching {$currentGame[0]}...</h1>
                <p>The game should launch automatically.</p>
            </nav>
            <hr/>

            <h3><em>Not Launching?</em></h3>
            <div id="steps">
                <div>
                    <img src="/images/fivem.png" class="platform" alt="FiveM logo"/>
                    <br/>
                    <strong>Step 1:</strong> Install FiveM
                    <br/>
                    <a href="https://fivem.net/" target="_blank" class="button">FiveM</a>
                </div>
                <div>
                    <img src="/images/{$currentGame[2]}" class="platform" alt={$currentGame[0] + " Logo"}/>
                    <br/>
                    <strong>Step 2:</strong> Launch <u>{$currentGame[0]}</u>
                    <br/>
                    <a href={$currentGame[1]} class="primary button">Launch</a>
                </div>
            </div>
        </section>
    </aside>
{:else}
    <div></div>
{/if}

<style>
    #intro {
        text-align: center;
    }

    h1, h3, p {
        margin: 0;
    }

    a:not(.button) {
        color: white;
        font-weight: bold;
        text-decoration: underline;
    }

    img.platform {
        display: inline-block;
        max-width: 46px;
        height: auto;
        margin-bottom: .5em;
    }

    hr {
        margin: 1em 0;
    }

    a.button {
        margin-top: .75rem;
        display: inline-block;
        padding: .75rem 1.5rem;
        color: white;
        font-size: 1.125rem;
        font-weight: 900;
        text-shadow: 0 1px 4px rgba(0, 0, 0, 0.9);
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.75);
        background: dodgerblue;
    }

    a.button.primary {
        background: var(--primary);
    }

    a.button:hover {
        cursor: pointer;
        text-decoration: none;
    }

    h1 {
        font-size: 2rem;
        line-height: 1em;
        margin-bottom: .5rem;
        color: var(--primary);
        text-shadow: 0 1px 3px #000;
    }

    h3 {
        margin-bottom: 1em;
    }

    p {
        color: #ccc;
    }

    h3, #steps {
        text-align: center;
    }

    #steps {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    aside {
        width: 100vw;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    section {
        border: 1px solid var(--primary);
        padding: 2rem;
        width: 500px;
        min-height: 100px;
        background: #222;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.9);
    }
</style>