<script lang="ts">
    import LanderHero from "./lander/LanderHero.svelte";
    import LanderGamesUpsell from "./lander/LanderGamesUpsell.svelte";
    import Navbar from "./layout/Navbar.svelte";
    import GameLaunchModal from "./game-launch/GameLaunchModal.svelte";
</script>

<main>
    <!-- Modals -->
    <GameLaunchModal/>
    
    <!-- Lander -->
    <section>
        <LanderHero/>
        <Navbar/>
        <LanderGamesUpsell/>
    </section>
</main>

<style>
    :root {
        --primary: orangered;
        --primary-hover: #882600;
    }
    
    main {
        display: flex;
        justify-content: center;
    }
</style>