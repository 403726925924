<div>
    <nav>
        <a href="/">Home</a>
        <a href="https://gg-driftmania.tebex.io/" target="_blank">Store</a>
        <a href="https://discord.gg/5Sgneg586j" target="_blank">Discord</a>
    </nav>
    <hr/>
</div>

<style>
    nav {
        margin: 1.75em auto;
        max-width: 400px;
        display: grid;
        grid-template-columns: repeat(auto-fill, 125px);
        gap: .5em;
    }

    a {
        font-weight: 900;
        font-size: 2rem;
        color: #ccc;
        text-transform: uppercase;
        transition: all 200ms ease;
    }

    a:hover {
        color: white;
    }

    hr {
        border-color: var(--primary);
        margin-left: 1em;
        margin-right: 1em;
    }

    @media (max-width: 450px) {
        nav {
            grid-template-columns: 1fr;
            margin-left: 1em;
        }
    }
</style>