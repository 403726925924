<script>
    import {currentGame} from "../../stores"

    export let backgroundUrl = "";
    export let imageUrl;
    export let url;
    export let name;
    export let description;
    export let comingSoon = false;
    export let fiveM = false;
    export let redM = false;

    function onClick(event) {
        event.preventDefault()
        currentGame.set([name, url, imageUrl])
        window.location.href = url
    }

    $: cssVarStyles = `--bg-url:url(/images/${backgroundUrl})`
</script>

<article style={cssVarStyles}>
    <div id="left">
        <h2>
            {#if fiveM}<img src="/images/fivem.png" class="platform" alt="FiveM Logo"/>{/if}
            {#if redM}<img src="/images/redm.png" class="platform" alt="RedM Logo"/>{/if}
            {name}
        </h2>
        <p>{description}</p>
        {#if comingSoon}
            <a class="button disabled">{comingSoon || "Coming Soon"}</a>
        {:else}
            <a href="/" on:click={onClick} class="button">Play</a>
        {/if}
    </div>
    <div id="right">&nbsp;</div>
</article>

<style>
    img.platform {
        width: 30px;
        height: auto;
    }

    article {
        padding: 1em 2em;
        display: grid;
        min-height: 300px;
        grid-template-columns: repeat(2, 1fr);
        background: var(--bg-url);
        background-size: cover;
    }

    #left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #right {
        display: flex;
        align-items: center;
    }

    h2, p {
        margin: 0;
    }

    h2 {
        font-size: 3rem;
        font-weight: 900;
    }

    p {
        font-size: 1.125rem;
        font-weight: 700;
        text-transform: uppercase;
        color: #eee;
        margin-top: .125em;
        margin-bottom: 1.5em;
    }

    img {
        max-height: 64px;
        width: auto;
    }

    /* Mobile */
    @media (max-width: 450px) {
        article {
            background-size: initial;
            background-position: 400px center;
        }
    }

    @media (max-width: 800px) {
        article {
            grid-template-columns: 1fr;
            background-size: initial;
            background-position: left center;
        }

        h2 {
            font-size: 2.25rem;
        }

        p {
            text-align: center;
        }

        #right {
            display: none;
        }
    }

    /* Tablet */
    @media (min-width: 451px) and (max-width: 800px) {
        h2 {
            font-size: 3rem;
        }
    }
</style>