<script>
    import LanderGame from "./game/LanderGame.svelte";
</script>

<section>
    <LanderGame name="Drift:Mania" description="Drive, race, and drift with friends"
                url="fivem://connect/drift.goatgaming.xyz" imageUrl="drift.png"
                backgroundUrl="hero-drift.png" fiveM/>
    <hr/>
    <LanderGame name="Trucking Sim" description="A relaxed trucking simulator"
                url="fivem://connect/trucksim.goatgaming.xyz" imageUrl="trucksim.png"
                backgroundUrl="hero-trucking.png" fiveM/>
    <hr/>
    <LanderGame name="Hunting Sim" description="Hunt, skin, and make bank!"
                backgroundUrl="hero-hunting.png" imageUrl="farmsim.png" comingSoon="planned" redM/>
    <hr/>
    <LanderGame name="Farm Tycoon" description="Grow a farming empire!"
                backgroundUrl="hero-farming.png" imageUrl="farmsim.png" comingSoon="planned" fiveM/>
</section>

<style>
    section {
        display: grid;
        grid-gap: 1em;
        margin: 1.5em 1em 2em 1em;
    }

    hr {
        width: 100%;
        border-color: var(--primary);
    }
</style>